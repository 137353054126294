<template>
  <ValidationForm
    :validation-schema="passwordSchema"
    v-slot="{ meta }"
    @submit="submitChangingPassword"
  >
    <Card title="パスワード設定">
      <Label required bold size="m">パスワード</Label>
      <Box width="370px">
        <VeeTextField
          type="password"
          name="password"
          size="m"
          class="mt-2"
          autocomplete="new-password"
        />
      </Box>

      <Label bold size="m" required class="mt-4">もう一度入力</Label>
      <Typography color="grey" size="s" class="mt-2">
        確認のためもう一度入力してください
      </Typography>
      <Box width="370px">
        <VeeTextField
          type="password"
          name="confirmPassword"
          size="m"
          class="mt-2"
          autocomplete="new-password"
        />
      </Box>
    </Card>
    <Flex justify-center class="mt-6">
      <Button type="submit" :disabled="!meta.valid" :loading="isLoading">
        設定する
      </Button>
    </Flex>
  </ValidationForm>
</template>
<script>
import { defineComponent } from 'vue';
import * as Yup from 'yup';
import { VALIDATION_SCHEMA } from '@/common/validation';
import { Form as ValidationForm } from 'vee-validate';
import VeeTextField from '@/components/veeValidate/VeeTextField.vue';
import Box from '@/components/layout/Box.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import Flex from '@/components/layout/Flex.vue';
import Label from '@/components/ui/Label.vue';
import Typography from '@/components/ui/Typography.vue';
import { useChangePassword } from '@/composables/mypage';
import { MYPAGE_ROUTES } from '@/router';

export default defineComponent({
  name: MYPAGE_ROUTES.accountPassword,
  components: {
    Box,
    Button,
    Card,
    Flex,
    Label,
    Typography,
    ValidationForm,
    VeeTextField
  },
  setup() {
    const { password, confirmPassword } = VALIDATION_SCHEMA;
    const { submitChangingPassword, isLoading } = useChangePassword();

    return {
      passwordSchema: Yup.object().shape({
        password,
        confirmPassword
      }),
      submitChangingPassword,
      isLoading
    };
  }
});
</script>
